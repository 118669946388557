import React from "react";
import { motion } from "framer-motion";
import getMaxLetterWidth from "../util/animationUtils";

type LetterOrder = {
  [key: string]: number[];
};

const AnimateName = ({
  name,
  words,
  runAnimation,
}: {
  name: string;
  words: string[];
  runAnimation: boolean;
}) => {
  const letterOrder: LetterOrder = {};
  name
    .split("")
    .map((letter) => letter.toLowerCase())
    .forEach((letter, index) => {
      letterOrder[letter] = letterOrder[letter] ?? [];
      letterOrder[letter].push(index);
    });

  let endIndex = 0;

  // const maxLetterWidth = Math.ceil(getMaxLetterWidth(name, 1.875));
  // console.log(maxLetterWidth);

  const maxLetterWidth = 23;

  return (
    <div className="text-3xl text-base-content flex">
      {words.map((word) => {
        return (
          <>
            {word.split("").map((letter) => {
              const endPos =
                maxLetterWidth *
                ((letterOrder[letter]?.shift() ?? 0) - endIndex);
              endIndex += 1;
              return (
                <motion.div
                  animate={{ x: runAnimation ? endPos : 0 }}
                  transition={{ type: "spring" }}
                  className="p-0 m-0 text-center"
                  style={{ width: maxLetterWidth + "px" }}
                >
                  {letter}
                </motion.div>
              );
            })}
            <motion.div
              className="p-0 m-0"
              style={{ width: maxLetterWidth + "px" }}
            >
              &nbsp;
            </motion.div>
            {endIndex++ && ""}
          </>
        );
      })}
    </div>
  );
};

export default AnimateName;

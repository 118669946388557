import React from "react";
import AnimateName from "./animateName";

const NameModal = ({ words, name }: { words: string[]; name: string }) => {
  const [runAnimation, setRunAnimation] = React.useState(false);
  return (
    <dialog id="animate-modal" className="modal">
      <div className="modal-box modal-bottom sm:modal-middle sm:w-full">
        <AnimateName words={words} name={name} runAnimation={runAnimation} />
        <button className="btn" onClick={() => setRunAnimation(!runAnimation)}>
          Animate
        </button>
        <div className="modal-action">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn">Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default NameModal;

import { createStore } from 'redux';

const initialState = {
letterFrequency: {},
words: [],
hist: [],
name: '',
modelSettings: {
  standardCorpus: {
    africanDeities: 100,
    aztecDeities: 100,
    celticDeities: 100,
    egyptianDeities: 100,
    fantasy: 100,
    greekDeities: 100,
    hinduDeities: 100,
    incanDeities: 100,
    japaneseDeities: 100,
    mayanDeities: 100,
    nativeAmericanDeities: 100,
    norseDeities: 100,
    romanDeities: 100,
  },
  user: {
    userCorpus: 0,
    url: null
  }
}
};

function reducer(state = initialState, action) {
  let hist = state.hist;
  let words = state.words;
  let letterFrequency = state.letterFrequency;

  switch (action.type) {
    case 'UPDATE_DATA':
      return { ...state, letterFrequency: action.payload.letterFrequency, words: action.payload.words };
    case 'REORDER_WORDS' :
      return { ...state, words: action.payload };
    case 'UPDATE_MODEL_SETTINGS':
      return { ...state, modelSettings: action.payload };
    case 'POP': // TODO: refactor to delete
      hist.splice(action.payload, 1);
      hist = [...hist];
      return { ...state, hist: hist };
    case 'PULL':
      words = [...hist[action.payload].words.map((word, i) => ({word: word, id: Date.now() + i}))];
      letterFrequency = {...hist[action.payload].letterFrequency};
      return { ...state, hist: hist, words: words, letterFrequency: letterFrequency };
    case 'PUSH':
      hist = [...state.hist];
      letterFrequency = {...state.letterFrequency};
      words = [...state.words];
      hist.push({words: words.map(word => word.word), letterFrequency: letterFrequency});
      return { ...state, hist: hist };
    case 'CLEAR':
      return { ...state, letterFrequency: {}, words: [], hist: [], name: action.payload };
    default:
      return state;
  }
}

const store = createStore(reducer);

export default store;

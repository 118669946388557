import React, { useEffect, useState } from "react";
import Word from "./word";
import { Reorder } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";

export default function ReorderableWordGroup(props) {
  const words = useSelector((state) => state.words);
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);

  function handleReorder(newWordOrder) {
    setDragging(true);
    dispatch({ type: "REORDER_WORDS", payload: newWordOrder });
  }

  function handleDragEnd() {
    setTimeout(() => {
      setDragging(false);
    }, 1)
  }

  return (
    <Reorder.Group
      values={words}
      axis="x"
      onReorder={handleReorder}
      className="max-h-[50vh] overflow-y-auto sm:max-h-none flex"
      >
      {words.map((word, index) => {
        return (
          <Reorder.Item key={word.id} value={word}
            onDragEnd={handleDragEnd} >
            <Word
              dragging={dragging}
              word={word.word}
              type={props.type}
              index={index}
              clearInput={props.clearInput}
            />
          </Reorder.Item>
        );
      })}
    </Reorder.Group>
  );
}

import WordGroup from "./wordGroup";
import { useSelector, useDispatch } from "react-redux";
import { frequencyToString, isEmpty } from "../util/frequencyUtils";
import AnimateName from "./animateName";
import { useState } from "react";
import NameModal from "./nameModal";

//alias to import functional react component
export default function History() {
  let history = useSelector((state) => state.hist);
  const name = useSelector((state) => state.name);
  const dispatch = useDispatch();

  function pop(index) {
    let data = index;
    dispatch({ type: "POP", payload: data });
  }

  function pull(index) {
    let data = index;
    dispatch({ type: "PULL", payload: data });
  }

  function push() {
    dispatch({ type: "PUSH" });
  }
  const [wordsToAnimate, setAnimatedWords] = useState([]);

  return (
    <div className="p-2 flex-1 bg-neutral text-neutral-content rounded max-h-[65vh] sm:max-h-none overflow-y-auto">
      <div className="flex items-center">
        <h1>History</h1>
        <div className="flex-1" />
        <button className="btn" onClick={push}>
          Save
        </button>
      </div>
      {history.map((attempt, index) => {
        return (
          <div key={index} className="flex m-3 join">
            <div
              key={index}
              onClick={() => pull(index)}
              className="p-1 join-item bg-base-100"
            >
              <WordGroup src={attempt.words} type="history" />
              {!isEmpty(history[index].letterFrequency) && (
                <p className="overflow-hidden badge badge-pill bg-secondary text-secondary-content text-ellipsis max-w-56">
                  {frequencyToString(attempt.letterFrequency)}
                </p>
              )}
            </div>
            {isEmpty(attempt.letterFrequency) && (
              <button
                className="bg-info text-info-content join-item p1"
                onClick={() => {
                  setAnimatedWords(attempt.words);
                  document.getElementById("animate-modal").showModal();
                }}
              >
                Animate
              </button>
            )}
            <button
              className="p-1 bg-error text-error-content join-item"
              onClick={() => pop(index)}
            >
              Delete
            </button>
          </div>
        );
      })}
      <NameModal words={wordsToAnimate} name={name} />
    </div>
  );
}

import React from "react";
import Word from "./word";

export default function WordGroup(props) {
  const words = props.src;

  return (
    <div className="max-h-[50vh] overflow-y-auto sm:max-h-none ">
      {words.map((word, index) => {
        return (
          <Word
            key={index}
            word={word}
            type={props.type}
            index={index}
            clearInput={props.clearInput}
          />
        );
      })}
    </div>
  );
}

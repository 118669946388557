import { cleanFrequency, getLetterFrequencySize, isEmpty, removeLetter } from "./frequencyUtils";

const MINIMUM_WORD_FREQUENCY = 0.00001;

async function getModel(modelSettings) {
    const corpora = Object.keys(modelSettings.standardCorpus);
    let weights = corpora.map((key) => modelSettings.standardCorpus[key] / 100);
    let models = corpora.map((key) => fetch(`https://magigram.us/nameModel/models/${key}Corpus.json`).then(response => response.json()))
    if (modelSettings.user.url && modelSettings.user.userCorpus > 0) {
        models.push(await fetch(modelSettings.user.url).then(response => response.json()));
        weights.push(modelSettings.user.userCorpus / 100);
    }

    let masterModel = {};
    
    for (let i = 0; i < models.length; i++) {
        const model = await models[i];
        for (const letter in model) {
            const nextLetters = masterModel[letter] ?? {};
            
            for (const nextLetter in model[letter]) {
                if (nextLetters[nextLetter] === undefined) nextLetters[nextLetter] = 0;
                nextLetters[nextLetter] += model[letter][nextLetter] * weights[i];
            }
            
            masterModel[letter] = nextLetters;
        }
    }

    return masterModel;
}

// removes letters which cannot be used in the name from the letter frequency
function validateModel(model, letterFrequency) {
    // remove letters from model that are not in the letter frequency
    let validModel = {}
    for (const letter in letterFrequency) {
        const validNextLetters = {};

        // in case the model does not include a letter in the frequency add it to the model to avoid undefined errors
        if (model[letter] === undefined) model[letter] = {}
        for (const nextLetter in letterFrequency) {
            validNextLetters[nextLetter] = model[letter][nextLetter] || MINIMUM_WORD_FREQUENCY; // a small value to ensure it is not 0 but not probable
        }

        validModel[letter] = validNextLetters;
    }

    // " " won't be in the frequency but is needed to pick the first letter of a name
    const validNextLetters = {};

    for (const nextLetter in letterFrequency) {
        validNextLetters[nextLetter] = model[" "][nextLetter] || MINIMUM_WORD_FREQUENCY; // a small value to ensure it is not 0 but not probable
    }

    validModel[" "] = validNextLetters;

    
    return validModel;
}

export async function generateTransformerPoweredNames(letterFrequency, modelSettings) {
    if (isEmpty(letterFrequency)) return [];
    const lettersForName = cleanFrequency({ ...letterFrequency }); // should not be needed if letterFrequency is maintained properly
    const model = validateModel(await getModel(modelSettings), lettersForName);
    let names = [];
    for (let i = 0; i < 20; i++) {
        names.push(generateName(model, lettersForName));
    }
    return names;
}

function generateName(model, lettersForName) {
    lettersForName = { ...lettersForName };
    model = structuredClone(model);
    let letter;
    let name = "";

    const frequencySize = getLetterFrequencySize(lettersForName);

    // refactor this section into the  loop by making the first letter of name " " then chop that off before returning the name
    // there might be issues with delete model[" "]
    const firstLetter = selectNextLetter(1, lettersForName, model[" "]);
    name += firstLetter;
    delete model[" "];
    removeSelectedLetter(firstLetter, lettersForName, model);
    for (let i = 0; i < frequencySize - 1; i++) {
        letter = selectNextLetter(1, lettersForName, model[name[i]]);
        name += letter;
        removeSelectedLetter(letter, lettersForName, model);
    }
    return name;
}

function selectNextLetter(temperature, lettersForName, nextLetterFrequency) {
    let chosenLetter;
    const nextLetterProbability = { ...nextLetterFrequency };
    // create percentages for each letter adding up to 1
    let sum = 0;
    for (const letter in nextLetterProbability) {
        sum += nextLetterProbability[letter];
    }
    for (const letter in nextLetterProbability) {
        nextLetterProbability[letter] = nextLetterProbability[letter] / sum;
    }
    
    // sort valid letters by probability
    let validLetters = [];
    for (const letter in lettersForName) {
        validLetters.push(letter);
    }
    validLetters.sort((a, b) => nextLetterProbability[b] - nextLetterProbability[a]);

    // select a random number between 0 and 1 * temperature
    // works by selecting a random number and checking if it is in the range of the probability of the letter
    // the temperature is used to limit the range of the probability to the top x% of the probability
    let random = 1 - Math.random() * temperature;
    sum = 1;
    for (let i = 0; i < validLetters.length; i++) {
        const letter = validLetters[i];
        sum -= nextLetterProbability[letter];
        if (random > sum) {
            chosenLetter = letter;
            break;
        }
    }
    
    // return letter and new lettersForName
    return chosenLetter;
}

function removeLetterFromModel(model, letter) {
    for (const firstLetter in model) {
        delete model[firstLetter][letter];
    }
    
    return model;
}

function removeSelectedLetter(letter, lettersForName, model) {
    lettersForName = removeLetter(lettersForName, letter);
    if (!lettersForName[letter]) {
        model = removeLetterFromModel(model, letter);
    }
    return [lettersForName, model];
}
import React from 'react';
import Header from "./components/header";
import History from "./components/history";
// import Foot from "./components/foot";
import LetterBank from './components/letterBank';
import Name from './components/name';
import './CompiledApp.css';
import { useEffect } from 'react'
import { themeChange } from 'theme-change'
import NameBuilder from './components/nameBuilder';
import Foot from './components/foot';

function App() {
  useEffect(() => {
    themeChange(false)
    // 👆 false parameter is required for react project
  }, [])

  return (
    <>
      <Header />
        <div className='grid gap-2 md:grid-cols-2 lg:grid-cols-4 m-2'>
              <Name />
              <LetterBank />
              <History />
              <NameBuilder />
        </div>
      <div className=' bg-neutral rounded mt-auto' >
        <Foot />
      </div>
    </>
  );
}

export default App;

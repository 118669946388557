import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeLetters, cleanText } from "../util/frequencyUtils";
import WordGroup from "./wordGroup";
import { getWordChoices } from "../util/getWordChoices";
import CustomizeModelBtn from "./customizeModelBtn";

export default function WordFinder(props) {
  const dispatch = useDispatch();
  let words = useSelector((state) => state.words);
  let letterFrequency = useSelector((state) => state.letterFrequency);
  const [word, setWord] = useState("");
  const [wordChoices, setWordChoices] = useState([]);
  useEffect(() => {
    setWordChoices(
      getWordChoices(props.filter, props.type, letterFrequency, word)
    );
  }, [letterFrequency, word, props.type, props.filter]);

  let clearInput = () => {
    setWord("");
  };

  function selectWord() {
    if (!removeLetters(letterFrequency, word)) {
      return;
    }
    const wordsToAdd = word
      .split(" ")
      .map((word) => cleanText(word))
      .filter((word) => word.length > 0)
      .map((word) => ({ word, id: Date.now() }));
    words = [...words, ...wordsToAdd];
    letterFrequency = { ...letterFrequency };
    let data = { letterFrequency, words };
    //update the state of the store with the new word in the words list
    dispatch({ type: "UPDATE_DATA", payload: data });
    setWord(""); // clears input field
  }

  return (
    <>
      <div className="flex flex-wrap mt-1 join text-base-content overflow-auto">
        <input
          className="sm:join-item input input-md w-full sm:w-auto sm:flex-1 min-w-60"
          type="text"
          value={word}
          onChange={(e) => setWord(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              selectWord();
            }
          }}
        />
        <select
          onChange={(e) => props.setType(e.target.value)}
          defaultValue="Filter"
          className="select select-bordered join-item select-md flex-1"
        >
          <option disabled>Filter</option>
          <option>{props.types[0]}</option>
          <option>{props.types[1]}</option>
          <option>{props.types[2]}</option>
        </select>
        <select
          onChange={(e) => props.setSearch(e.target.value)}
          defaultValue="Search"
          className="select select-bordered join-item select-md flex-1"
        >
          <option disabled>Search</option>
          <option>Starts With</option>
          <option>Contains</option>
        </select>
        {props.nameBuilder ? (
          <CustomizeModelBtn
            setWordChoices={setWordChoices}
            letterFrequency={letterFrequency}
          />
        ) : (
          <button className="join-item btn btn-md flex-1" onClick={selectWord}>
            Use word
          </button>
        )}
      </div>
      <WordGroup src={wordChoices} type="wordList" clearInput={clearInput} />
    </>
  );
}

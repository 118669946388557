import { React } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generateTransformerPoweredNames } from "../util/nameGen";

const CustomizeModelBtn = (props) => {
  let modelSettings = useSelector((state) => state.modelSettings);
  const dispatch = useDispatch();
  const corpusToTitle = (corpus) =>
    corpus.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });

  return (
    <>
      <button
        className="btn btn-md join-item flex-1"
        onClick={async () =>
          props.setWordChoices(
            await generateTransformerPoweredNames(
              props.letterFrequency,
              modelSettings
            )
          )
        }
      >
        Generate Names
      </button>
      <div className="join-item bg-base-200 flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="rounded-full hover:bg-primary fill-base-content"
          onClick={() => document.getElementById("model-modal").showModal()}
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z" />
        </svg>

        {/* Modal is placed within div to prevent join-item from not triggering last-child */}
        <dialog id="model-modal" className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Fine Tuning</h3>
            <p>
              Hint: sticking to one style will make name generation better.
              However, throwing out too many styles may degrade generation
              performance.
            </p>

            {Object.keys(modelSettings.standardCorpus).map((key, index) => (
              <div key={index}>
                <p className="label">{corpusToTitle(key)}:</p>
                <input
                  type="range"
                  min={0}
                  max="100"
                  defaultValue={modelSettings.standardCorpus[key]}
                  className="range range-primary"
                  onChange={(e) =>
                    (modelSettings.standardCorpus[key] = parseInt(
                      e.target.value
                    ))
                  }
                />
              </div>
            ))}

            <p className="label">Add your own:</p>
            <div className="flex flex-row items-center">
              <input
                type="text"
                placeholder="https://myModel.json"
                className="input input-bordered input-sm input-primary w-full max-w-xs"
                onChange={(e) => (modelSettings.user.url = e.target.value)}
              />
              <input
                type="range"
                min={0}
                max="100"
                defaultValue={modelSettings.user.userCorpus}
                className="ml-1 range range-primary"
                onChange={(e) =>
                  (modelSettings.user.userCorpus = parseInt(e.target.value))
                }
              />
            </div>
            <div className="label">
              <span className="label-text-alt">
                See the readme to create your own model
              </span>
            </div>

            <div className="modal-action">
              <form method="dialog">
                <button
                  className="btn"
                  onClick={() =>
                    dispatch({
                      type: "UPDATE_MODEL_SETTINGS",
                      payload: modelSettings,
                    })
                  }
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </dialog>
      </div>
    </>
  );
};

export default CustomizeModelBtn;
